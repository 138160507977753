import React from "react"
import { graphql, Link } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow, revealInOut } from "../helpers/transitionHelper"
import {Helmet} from "react-helmet";

import urlFor from '../helpers/imageBuilder'

const IndexPage = ({data, childAnimationDelay}) => {
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <Helmet>
        <title>Partners - {data.sanitySiteSettings.title}</title>
      </Helmet>
      <motion.div variants={fadeSlow}>
        <motion.div variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: childAnimationDelay
          }}
        }}>
          <motion.h1 variants={revealInOut} className="page-title">Partners</motion.h1>
          <motion.div 
            className="partner-list" 
            variants={{
              enter: { transition: { 
                staggerChildren: .05,
                delayChildren: 0
              }}
            }}>

            {data.allSanityPartners.nodes.map((partner, i) => (
              <motion.div className="partner-block" variants={revealInOut}>
                <img src={urlFor(partner.logo._rawAsset).width(400).url()} alt=""/>
              </motion.div>
            ))}
        
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default IndexPage

export const query = graphql`
  query PartnersQuery {
    sanitySiteSettings {
      title
    }
    allSanityPartners {
      nodes {
        title
        slug {
          current
        }
        logo {
            _rawAsset
        }
      }
    }
  }
`